<template>
  <v-app id="inspire">
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-step :complete="currentStep > 1" step="1" color="#0fac5f">
        <span> Pick a charger </span>
        <small v-show="isChargerSelected" style="padding-top: 5px"
          >Charger selected {{ selectedChargerSerialNumber }}</small
        >
        <small v-show="!isChargerSelected" style="padding-top: 5px"
          >List of available chargers under selected category</small
        >
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-alert
          dense
          border="left"
          type="error"
          color="red"
          v-show="errorWhileGeneratingOtp"
          class="white--text"
        >
          Error while generating OTP. Make sure charger is not linked to any
          customer in "Customer Management" tab and try again.
        </v-alert>
        <v-card elevation="1" outlined class="mb-12">
          <v-card-text>
            <v-tabs color="#0fac5f">
              <v-tab> Onboarding</v-tab>
              <v-tab> Operational</v-tab>
              <v-tab> OTP</v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-list-item-subtitle>
                      <v-autocomplete
                        v-model="selectedChargerId"
                        :items="onboardingChargers"
                        item-value="externalId"
                        item-text="serialNumber"
                        dense
                        label="Select a charger"
                        solo
                      ></v-autocomplete>
                    </v-list-item-subtitle>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-list-item-subtitle>
                      <v-autocomplete
                        v-model="selectedChargerId"
                        :items="operationalChargers"
                        item-value="externalId"
                        item-text="serialNumber"
                        dense
                        label="Select a charger"
                        solo
                      ></v-autocomplete>
                    </v-list-item-subtitle>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-list-item-subtitle>
                      <v-autocomplete
                        v-model="selectedChargerId"
                        :items="operationalChargers"
                        item-value="externalId"
                        item-text="serialNumber"
                        dense
                        label="Select a charger"
                        @change="getExistingOtpForCharger(selectedChargerId)"
                        solo
                      ></v-autocomplete>
                    </v-list-item-subtitle>

                    <v-btn
                      color="#0fac5f"
                      class="ma-2 white--text"
                      @click="generateOtp(selectedChargerId)"
                      :disabled="
                        selectedChargerId === '' ||
                        fetchingOtpInProgress ||
                        isRealCustomerLinked
                      "
                    >
                      Generate
                      <v-icon right dark> mdi-key-variant</v-icon>
                    </v-btn>

                    <small
                      v-show="isRealCustomerLinked"
                      style="padding-top: 5px"
                      >There's a customer linked to this charger. Unlink all
                      customers before generating OTP.
                    </small>

                    <small v-show="otp !== ''" style="padding-top: 5px"
                      >OTP generated: {{ otp }}
                      <v-btn x-small icon @click="copyToClipboard(otp)">
                        <v-icon x-small>mdi-content-copy</v-icon>
                      </v-btn>
                    </small>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
            <v-icon>mdi-information-variant</v-icon>
            Onboarding tab to onboard a new charger
            <br />
            <v-icon>mdi-information-variant</v-icon>
            Operational tab to reset the auth key for already onboarded charger
            <br />
            <v-icon>mdi-information-variant</v-icon>
            OTP tab to generate one time password for charger
            <br />
            <br />
          </v-card-text>
        </v-card>
        <v-btn
          class="ma-2 white--text"
          color="#0fac5f"
          @click="currentStep++"
          :disabled="!isChargerStatusTypeSelected"
        >
          Continue
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          @click="onCancel"
          :disabled="!isChargerStatusTypeSelected"
        >
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :rules="[
          () => !errorWhileMarkingChargerAsOnboarding,
          () => !errorWhileGeneratingOtp,
        ]"
        v-show="isChargerStatusTypeSelectedOperational"
        step="2"
        :complete="currentStep > 2"
        color="#0fac5f"
      >
        Mark charger as Onboarding
        <small v-show="isStatusChangedToOnboarding" style="padding-top: 5px"
          >{{ selectedChargerSerialNumber }} status changed to Onboarding</small
        >
      </v-stepper-step>
      <v-stepper-content
        v-show="isChargerStatusTypeSelectedOperational"
        step="2"
      >
        <v-card elevation="1" outlined class="mb-12">
          <v-progress-linear
            v-show="markingChargerAsOnbardingInProgress"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-card-text>
            <v-alert
              v-show="isStatusChangedToOnboarding"
              dense
              text
              type="success"
            >
              <strong>{{ selectedChargerSerialNumber }}</strong> charger marked
              as <strong>Onboarding</strong>.
            </v-alert>
            <v-alert
              v-show="
                !errorWhileMarkingChargerAsOnboarding &&
                !isStatusChangedToOnboarding
              "
              dense
              text
              type="warning"
            >
              Generating <strong>new</strong> auth key for already
              <strong>onboarded</strong> charger, could cause
              {{ selectedChargerSerialNumber }} charger to be
              <strong>inoperational</strong>, until new auth key is configured
              onto the charger
            </v-alert>
            <v-alert
              dense
              border="left"
              type="error"
              color="red"
              v-show="errorWhileMarkingChargerAsOnboarding"
              class="white--text"
            >
              Error while changing the status to <strong>Onboarding</strong> for
              charger {{ selectedChargerSerialNumber }}. Please try again after
              sometime.
            </v-alert>
            Would you like to change charger status and proceed to generate new
            auth key? <br />
            <v-row style="padding-left: 10px">
              <v-btn
                tile
                class="white--text ma-2"
                color="#0fac5f"
                @click="changeChargerStatusToOnboarding"
                :disabled="isStatusChangedToOnboarding"
              >
                Change
              </v-btn>
            </v-row>
            <br />
            <span v-show="!isStatusChangedToOnboarding">
              <v-icon>mdi-information-variant </v-icon>
              Charger status will be changed from
              <strong>Operational</strong> to <strong>Onboarding</strong>
              <br />
            </span>
            <span v-show="isStatusChangedToOnboarding">
              <v-icon>mdi-check </v-icon>
              Charger status changed from <strong>Operational</strong> to
              <strong>Onboarding</strong>
              <br />
            </span>
          </v-card-text>
        </v-card>
        <v-btn
          v-show="!errorWhileMarkingChargerAsOnboarding"
          class="ma-2 white--text"
          color="#0fac5f"
          @click="currentStep++"
          :disabled="!isStatusChangedToOnboarding"
        >
          Continue
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          @click="onCancel"
          :disabled="isStatusChangedToOnboarding"
        >
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :rules="[() => !errorWhileSavingAuthKey]"
        :complete="currentStep > getStep(3)"
        :step="getStep(3)"
        color="#0fac5f"
      >
        Generate Authorization Key
        <small v-show="authCode !== ''" style="padding-top: 5px"
          >OCPP URI connection string generated
          <v-btn
            v-show="isCopyAllowed"
            x-small
            icon
            @click="copyToClipboard(authCode)"
          >
            <v-icon x-small>mdi-content-copy</v-icon>
          </v-btn>
        </small>
      </v-stepper-step>
      <v-stepper-content :step="getStep(3)">
        <v-card elevation="1" outlined class="mb-12">
          <v-progress-linear
            v-show="savingAuthKeyInProgress"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-card-text>
            <v-alert v-show="authCode !== ''" dense text type="success">
              <strong>Auth key</strong> generated <strong>successfully</strong>.
            </v-alert>
            <v-alert
              dense
              border="left"
              type="error"
              color="red"
              v-show="errorWhileSavingAuthKey"
              class="white--text"
            >
              Error while saving auth key to
              <strong> asset management DB</strong>. Please try again after
              sometime.
            </v-alert>
            Generate authorization key for charger
            {{ selectedChargerSerialNumber }}
            <br />
            <br />
            <v-btn
              color="#0fac5f"
              class="ma-2 white--text"
              @click="generateAuth(selectedChargerId, randomString())"
              :disabled="authCode !== ''"
            >
              Generate
              <v-icon right dark> mdi-key-variant</v-icon>
            </v-btn>
            <br />
          </v-card-text>
          <v-card-text v-show="authCode !== ''"
            >Auth Key generated: <br />
            <span v-show="showMaskedKey"
              >{{ maskedAuthKey }}
              <v-btn icon @click="toggleEye"
                ><v-icon small>mdi-eye-off</v-icon></v-btn
              ></span
            >
            <span v-show="!showMaskedKey"
              >{{ authCode }}
              <v-btn icon @click="toggleEye"
                ><v-icon small> mdi-eye</v-icon></v-btn
              ></span
            >
            <v-btn
              v-show="isCopyAllowed"
              icon
              @click="copyToClipboard(authCode)"
            >
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
            <v-snackbar
              color="success"
              outlined
              right
              absolute
              v-model="clickedOnCopy"
              :timeout="800"
            >
              <v-icon>mdi-check</v-icon>
              Copy successful
            </v-snackbar>
          </v-card-text>
        </v-card>
        <v-btn
          v-show="!errorWhileSavingAuthKey"
          :disabled="authCode === ''"
          class="ma-2 white--text"
          color="#0fac5f"
          @click="currentStep++"
        >
          Continue
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          v-show="authCode === ''"
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :complete="currentStep > getStep(4)"
        :step="getStep(4)"
        color="bg-success"
      >
        Configure charger to use the generated auth key
        <small v-show="vendorType !== ''" style="padding-top: 5px"
          >{{ selectedChargerSerialNumber }}'s vendor is {{ vendorType }}</small
        >
      </v-stepper-step>
      <v-stepper-content :step="getStep(4)">
        <v-card elevation="1" outlined class="mb-12">
          <v-alert
            dense
            border="left"
            type="error"
            color="red"
            class="white--text"
            v-show="isAuthKeyConfigured === 'No'"
          >
            Make sure you understand the
            <strong>{{ vendorType }}</strong> specific
            <strong>instructions</strong> to configure auth key
          </v-alert>
          <v-card-text v-show="vendorType !== 'ELINTA'"
            >Please make sure to configure charger to use the generated OCPP
            Auth Key.
          </v-card-text>
          <v-card-text v-show="vendorType === 'ELINTA'"
            >Please make sure to configure charger to use the generated OCPP
            Auth Key by following below steps. <br /><br />
            <v-toolbar
              dense
              color="white lighten-2"
              dark
              flat
              height="40px"
              outlined
            >
              <v-card-text class="black--text"
                >{{ vendorType }} Vendor specific instructions
              </v-card-text>
            </v-toolbar>
            <v-tabs vertical color="#0fac5f">
              <v-tab> Step 1</v-tab>
              <v-tab> Step 2</v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-list-item-subtitle>
                      <span style="padding-bottom: 100px"
                        ><v-icon>mdi-information-variant</v-icon> Go to ELIOS
                        system
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://elios.cloud"
                          >https://elios.cloud/
                        </a>
                        (ask for user/pass) and from "Stations" list click on
                        the new charger to see details</span
                      >
                      <br />
                      <br />
                      <v-img
                        :src="elios1"
                        max-height="200"
                        max-width="700"
                        class="grey lighten-2"
                      ></v-img>
                      <br />
                      <br />
                    </v-list-item-subtitle>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-list-item-subtitle>
                      <v-icon>mdi-information-variant</v-icon>
                      Go to edit section and paste the copied string in
                      <span class="green--text">OCPP URI</span> input field as
                      show below and save configuration
                      <br />
                      <br />
                      <v-img
                        :src="elios2"
                        max-height="200"
                        max-width="700"
                        class="grey lighten-2"
                      ></v-img>
                      <v-img
                        :src="elios3"
                        max-height="200"
                        max-width="700"
                        class="grey lighten-2"
                      ></v-img>
                    </v-list-item-subtitle>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
        <v-btn
          class="ma-2 white--text"
          color="#0fac5f"
          @click="currentStep++, (isAuthKeyConfigured = '')"
        >
          Continue
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          @click="(currentStep = currentStep - 1), (isAuthKeyConfigured = '')"
        >
          Back
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        :rules="[
          () => isAuthKeyConfigured === 'Yes' || isAuthKeyConfigured === '',
        ]"
        :complete="currentStep > getStep(5)"
        :step="getStep(5)"
        color="bg-success"
      >
        Confirm Authorization Key was successfully configured?
        <small v-show="isAuthKeyConfigured !== ''" style="padding-top: 5px">{{
          isAuthKeyConfigured
        }}</small>
      </v-stepper-step>
      <v-stepper-content :step="getStep(5)">
        <v-card elevation="1" outlined class="mb-12">
          <v-card-text
            >Do you confirm that new key was successfully configured?
            <br /><br />
            <v-row style="padding-left: 10px">
              <v-btn
                tile
                class="white--text ma-2"
                color="#0fac5f"
                @click="onAuthKeyConfigured"
              >
                <v-icon> mdi-check</v-icon>
                Yes
              </v-btn>
              <v-btn tile class="ma-2" @click="onAuthKeyNotConfigured">
                <v-icon> mdi-close</v-icon>
                No
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-stepper-content>
      <v-stepper-step
        :rules="[() => !errorWhileTestingConnection]"
        :complete="currentStep > getStep(6)"
        :step="getStep(6)"
        color="bg-success"
      >
        Test connection
        <small v-show="testConnectionResult !== ''" style="padding-top: 5px">{{
          testConnectionResult
        }}</small>
      </v-stepper-step>
      <v-stepper-content :step="getStep(6)">
        <v-card elevation="1" outlined class="mb-12">
          <v-progress-linear
            v-show="testConnectionInProgress"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-alert
            v-show="testConnectionResult === 'Successful'"
            dense
            text
            type="success"
          >
            <strong>{{ selectedChargerSerialNumber }}</strong> is able to
            connect successfully with new <strong>Auth Key</strong>.
          </v-alert>
          <v-card-text v-show="testConnectionResult === 'Successful'">
            <v-icon>mdi-check</v-icon>
            Test connection successful for the charger
            {{ selectedChargerSerialNumber }}
          </v-card-text>
          <v-alert
            dense
            border="left"
            type="error"
            color="red"
            v-show="errorWhileTestingConnection"
            class="white--text"
          >
            Please try again in a <strong>short</strong> while.
          </v-alert>
          <v-card-text
            >Test connection to check everything is fine, this might take a
            while... <br /><br />
            <v-icon>mdi-information-variant</v-icon>
            We would attempt to
            <strong> disconnect </strong> the charger and then
            <strong>reconnect</strong> so that new auth key will be be used
          </v-card-text>
        </v-card>
        <v-overlay :value="testConnectionInProgress">
          <v-icon class="white--text">mdi-power-standby</v-icon>
          Please standby, while we test connection
          <v-divider></v-divider>
          <span v-show="disconnectEvent">
            Charger disconnected, attempting to reconnect in
            <v-icon class="white--text">mdi-clock</v-icon>
            <strong>
              {{
                timerSeconds | prependZeroIncaseOfSingleDigit(timerMinutes)
              }}</strong
            ></span
          >
          <span
            v-show="chargerConnectionStatus === undefined"
            id="chargerOffline"
          >
            Charger is offline, waiting for charger to be online in
            <v-icon class="white--text">mdi-clock</v-icon>
            <strong>
              {{
                timerSeconds | prependZeroIncaseOfSingleDigit(timerMinutes)
              }}</strong
            ></span
          >
        </v-overlay>
        <v-btn
          v-show="
            testConnectionResult === '' || testConnectionResult === 'Failed'
          "
          class="ma-2 white--text"
          color="#0fac5f"
          @click="testConnection"
        >
          Test
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          v-show="testConnectionResult === ''"
          @click="
            (currentStep = currentStep - 1),
              (errorWhileTestingConnection = false)
          "
        >
          Back
        </v-btn>
        <v-btn
          class="ma-2 black--text"
          v-show="testConnectionResult === 'Failed'"
          @click="(currentStep = currentStep - 2), (testConnectionResult = '')"
        >
          {{ vendorType }} Instructions
        </v-btn>
        <v-btn
          v-show="testConnectionResult === 'Successful'"
          class="ma-2 white--text"
          color="#0fac5f"
          @click="currentStep++, (errorWhileTestingConnection = false)"
        >
          Continue
        </v-btn>
        <v-btn
          class="ma-2"
          v-show="errorWhileTestingConnection"
          @click="onCancel"
        >
          Cancel
        </v-btn>
        <span v-show="errorWhileTestingConnection">
          <v-tooltip right id="skipButtonToolTip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ma-2"
                @click="currentStep++"
                id="skipButton"
              >
                Skip
              </v-btn>
            </template>
            <span
              ><v-icon class="white--text" small
                >mdi-information-variant</v-icon
              >
              Skip this step, only if it is absolutely needed.
            </span>
          </v-tooltip>
        </span>
      </v-stepper-content>
      <v-stepper-step :step="getStep(7)" color="bg-success">
        Mark charger as Operational
        <small v-show="isStatusChangedToOperational" style="padding-top: 5px"
          >{{ selectedChargerSerialNumber }} status changed to
          Operational</small
        >
      </v-stepper-step>
      <v-stepper-content :step="getStep(7)">
        <v-card elevation="1" outlined class="mb-12">
          <v-progress-linear
            v-show="
              markingChargerAsOperationalInprogress || generatingOtpInProgress
            "
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-alert
            dense
            border="left"
            type="error"
            color="red"
            v-show="errorWhileGeneratingOtp"
            class="white--text"
          >
            Error while generating OTP. Make sure charger is not linked to any
            customer in "Customer Management" tab and try to generate OTP again
            in "OTP" tab.
          </v-alert>
          <v-alert
            dense
            border="left"
            type="error"
            color="red"
            v-show="errorWhileMarkingChargerAsOperational"
            class="white--text"
          >
            Error while changing the status to <strong>Operational</strong> for
            charger {{ selectedChargerSerialNumber }}. Please try again after
            sometime.
          </v-alert>
          <v-alert
            v-show="isStatusChangedToOperational"
            dense
            text
            type="success"
          >
            <strong>{{ selectedChargerSerialNumber }}</strong> charger marked as
            <strong>Operational</strong>.
          </v-alert>
          <v-card-text
            >Successfully configured the Auth key for the charger, change status
            of charger {{ selectedChargerSerialNumber }} to
            <strong>Operational</strong>.
            <br />
            <br />
            <v-btn
              tile
              class="white--text ma-2"
              color="#0fac5f"
              @click="changeChargerStatusToOperational"
              :disabled="isStatusChangedToOperational"
            >
              Change
            </v-btn>
            <br />
            <br />
            <span v-show="isStatusChangedToOperational">
              <v-icon>mdi-check </v-icon>
              Charger status changed to <strong>Operational</strong>
            </span>
            <div>
              <span v-show="otp !== ''"
                ><v-icon>mdi-check </v-icon> OTP generated:
                <strong>{{ otp }}</strong>
                <v-btn x-small icon @click="copyToClipboard(otp)">
                  <v-icon x-small>mdi-content-copy</v-icon>
                </v-btn>
              </span>
            </div>
          </v-card-text>
        </v-card>
        <v-btn
          class="white--text"
          color="#0fac5f"
          v-show="isStatusChangedToOperational"
          @click="currentStep = 1"
          v-on:click="onBoardingComplete(selectedChargerId)"
        >
          Finish
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-app>
</template>
<script>
import { changeChargerAuth } from "@/api/ChargerAuthApi";
import { getChargers } from "@/api/ChargersListApi";
import { triggerChargerCommands } from "@/api/CentralSystemApi";
import { getChargerEventByName } from "@/api/ChargerEventsApi";
import { updateChargerData } from "@/api/ChargerDetailsApi";
import { createOtp, getOtpForCharger } from "@/api/OneTimePasswordApi";
import moment from "moment";
import { copyToClipboard, randomString } from "@/utils/onboardingUtils";

export default {
  data() {
    return {
      currentStep: 1,
      onboardingChargers: [],
      operationalChargers: [],
      selectedChargerId: "",
      selectedChargerStatusType: "",
      selectedChargerSerialNumber: "",
      authKey: {},
      authCode: "",
      otp: "",
      isRealCustomerLinked: false,
      chargerUUID: "",
      chargersList: "",
      chargersListWithStatusOperational: "",
      chargersListWithStatusOnboarding: "",
      maskedAuthKey: "",
      showMaskedKey: true,
      isCopyAllowed: false,
      clickedOnCopy: false,
      vendorType: "",
      elios1: require("../assets/images/Elios1.png"),
      elios2: require("../assets/images/Elios2.png"),
      elios3: require("../assets/images/Elios3.png"),
      isAuthKeyConfigured: "",
      isStatusChangedToOnboarding: false,
      testConnectionInProgress: false,
      testConnectionResult: "",
      timerSeconds: 59,
      timerMinutes: 4,
      checkForEventsFrom: "",
      checkForConnectEventByPolling: "",
      isStatusChangedToOperational: false,
      disconnectEvent: false,
      connectEvent: "",
      errorWhileSavingAuthKey: false,
      errorWhileGeneratingOtp: false,
      errorWhileMarkingChargerAsOnboarding: false,
      errorWhileMarkingChargerAsOperational: false,
      errorWhileTestingConnection: false,
      savingAuthKeyInProgress: false,
      generatingOtpInProgress: false,
      fetchingOtpInProgress: false,
      markingChargerAsOnbardingInProgress: false,
      markingChargerAsOperationalInprogress: false,
      chargerConnectionStatus: "",
    };
  },
  watch: {
    selectedChargerId(chargerId) {
      this.setVendorType(this.selectedChargerId);
      this.selectedChargerStatusType = this.getChargerStatusType(chargerId);
      this.selectedChargerSerialNumber = this.getChargerSerialNumber(chargerId);
      this.otp = "";
      this.isRealCustomerLinked = false;
    },
  },
  filters: {
    prependZeroIncaseOfSingleDigit(secondsValue, minutes) {
      const newVal = "0".concat(secondsValue).slice(-2);
      return `${minutes}:${newVal}`;
    },
  },
  computed: {
    isChargerSelected() {
      return this.selectedChargerId !== "";
    },
    isChargerStatusTypeSelectedOperational() {
      return this.selectedChargerStatusType === "OPERATIONAL";
    },
    isChargerStatusTypeSelected() {
      return this.selectedChargerStatusType !== "";
    },
  },
  mounted() {
    setInterval(() => {
      if (this.testConnectionInProgress) {
        this.timerSeconds -= 1;
        if (this.timerSeconds <= 0) {
          this.timerSeconds = 59;
          this.timerMinutes -= 1;
        }
        if (this.timerMinutes < 0) {
          this.markStepAsTimedOut();
        }
      }
    }, 1000);
  },
  methods: {
    randomString,
    copyToClipboard,
    changeChargerStatusToOperational() {
      this.markingChargerAsOperationalInprogress = true;
      updateChargerData(this.selectedChargerId, { status: "OPERATIONAL" })
        .then(() => {
          this.isStatusChangedToOperational = true;
          this.markingChargerAsOperationalInprogress = false;
          this.errorWhileMarkingChargerAsOperational = false;
          this.generateOtp(this.selectedChargerId);
        })
        .catch((error) => {
          this.errorWhileMarkingChargerAsOperational = true;
          this.markingChargerAsOperationalInprogress = false;
          this.isStatusChangedToOperational = false;
          console.log(
            "Error occured while changing status to operational",
            error
          );
        });
    },
    markStepAsTimedOut() {
      this.testConnectionInProgress = false;
      this.testConnectionResult = "Failed";
      this.errorWhileTestingConnection = true;
      this.timerSeconds = 59;
      this.timerMinutes = 4;
    },
    testConnection() {
      this.testConnectionInProgress = true;
      this.checkForEventsFrom = moment().utc().format();
      this.chargerConnectionStatus = this.$store.getters.getChargerStatusByChargerId(
        this.selectedChargerId
      );
      if (
        this.chargerConnectionStatus !== undefined &&
        this.chargerConnectionStatus.connected
      ) {
        triggerChargerCommands(this.selectedChargerId, "Disconnect")
          .then(() => {
            this.disconnectEvent = true;
          })
          .catch((error) => {
            this.errorWhileTestingConnection = true;
            this.testConnectionInProgress = false;
            console.log(
              "Error occured while triggering Disconnect command",
              error
            );
          });
      }

      this.checkForConnectEventByPolling = setInterval(() => {
        if (this.testConnectionInProgress) {
          getChargerEventByName(
            this.selectedChargerId,
            this.checkForEventsFrom,
            "Connect"
          )
            .then((response) => {
              if (response.data.payload.length > 0) {
                this.connectEvent = response.data.payload;
                this.testConnectionResult = "Successful";
                this.testConnectionInProgress = false;
                clearInterval(this.checkForConnectEventByPolling);
              }
            })
            .catch((error) => {
              this.errorWhileTestingConnection = true;
              this.testConnectionInProgress = false;
              console.log(
                "Error occured while polling connect event for the charger",
                error
              );
            });
        }
      }, 30000);
    },
    changeChargerStatusToOnboarding() {
      this.markingChargerAsOnbardingInProgress = true;
      updateChargerData(this.selectedChargerId, { status: "ONBOARDING" })
        .then(() => {
          this.isStatusChangedToOnboarding = true;
          this.markingChargerAsOnbardingInProgress = false;
          this.errorWhileMarkingChargerAsOnboarding = false;
        })
        .catch((error) => {
          this.errorWhileMarkingChargerAsOnboarding = true;
          this.markingChargerAsOnbardingInProgress = false;
          this.isStatusChangedToOnboarding = false;
          console.log(
            "Error occured while changing status to Onboarding",
            error
          );
        });
    },
    getStep(step) {
      return this.isChargerStatusTypeSelectedOperational ? step : step - 1;
    },
    onAuthKeyConfigured() {
      this.currentStep++;
      this.isAuthKeyConfigured = "Yes";
    },
    onAuthKeyNotConfigured() {
      this.isAuthKeyConfigured = "No";
      this.currentStep--;
    },
    toggleEye() {
      this.showMaskedKey = !this.showMaskedKey;
    },
    maskAuthKey(key) {
      return [...key].map((_) => "*").join("");
    },
    onCancel() {
      this.currentStep = 1;
      this.selectedChargerId = "";
      this.selectedChargerStatusType = "";
      this.selectedChargerSerialNumber = "";
      this.vendorType = "";
      this.authCode = "";
      this.otp = "";
      this.isRealCustomerLinked = false;
      this.authKey = {};
      this.maskedAuthKey = "";
      this.isAuthKeyConfigured = "";
      this.isStatusChangedToOnboarding = false;
      this.errorWhileSavingAuthKey = false;
      this.errorWhileGeneratingOtp = false;
      this.errorWhileMarkingChargerAsOnboarding = false;
      this.savingAuthKeyInProgress = false;
      this.generatingOtpInProgress = false;
      this.fetchingOtpInProgress = false;
      this.markingChargerAsOnbardingInProgress = false;
      this.errorWhileTestingConnection = false;
      this.chargerConnectionStatus = "";
    },
    getChargerDetailsByExternalId(externalId) {
      return this.chargersList.filter(function (charger) {
        return charger.externalId === externalId;
      });
    },
    getChargerStatusType(externalId) {
      const charger = this.getChargerDetailsByExternalId(externalId);
      return charger[0].status;
    },
    setVendorType(externalId) {
      const charger = this.getChargerDetailsByExternalId(externalId);
      this.vendorType = charger[0].type;
    },
    getChargerUUIDByExternalId(externalId) {
      const charger = this.getChargerDetailsByExternalId(externalId);
      return charger[0].id;
    },
    getChargerSerialNumber(externalId) {
      const charger = this.getChargerDetailsByExternalId(externalId);
      return charger[0].serialNumber;
    },
    onBoardingComplete() {
      location.reload();
    },
    getExistingOtpForCharger(externalId) {
      this.fetchingOtpInProgress = true;
      getOtpForCharger(externalId)
        .then((response) => {
          const customerCharger = response.data[0];
          if (!customerCharger) {
            return;
          }

          if (customerCharger.customerType === "REAL") {
            this.isRealCustomerLinked = true;
          } else if (customerCharger.otp) {
            this.otp = customerCharger.otp;
          }
        })
        .catch(() => {
          this.isRealCustomerLinked = false;
          this.otp = "";
        })
        .finally(() => {
          this.fetchingOtpInProgress = false;
        });
    },
    generateOtp(externalId) {
      this.generatingOtpInProgress = true;
      const chargerId = this.getChargerUUIDByExternalId(externalId);
      createOtp(chargerId)
        .then((response) => {
          this.otp = response.data.otp;
          this.errorWhileGeneratingOtp = false;
        })
        .catch(() => {
          this.errorWhileGeneratingOtp = true;
        })
        .finally(() => {
          this.generatingOtpInProgress = false;
        });
    },
    generateAuth(externalId, authKey) {
      const vm = this;
      const ocppEndPoint = `${process.env.VUE_APP_ENDPOINT_URI}`;
      vm.authCode = `wss://${externalId}:${authKey}@${ocppEndPoint}/ocpp/${externalId}`;
      vm.maskedAuthKey = vm.maskAuthKey(vm.authCode);
      vm.chargerUUID = vm.getChargerUUIDByExternalId(externalId);
      this.saveAuth(vm.chargerUUID, authKey);
    },
    saveAuth(id, authKey) {
      this.savingAuthKeyInProgress = true;
      changeChargerAuth(id, authKey)
        .then(() => {
          this.isCopyAllowed = true;
          this.errorWhileSavingAuthKey = false;
          this.savingAuthKeyInProgress = false;
        })
        .catch((error) => {
          this.errorWhileSavingAuthKey = true;
          this.savingAuthKeyInProgress = false;
          console.log("error occured while saving auth key", error);
        });
    },
    getAllChargers() {
      const vm = this;
      getChargers()
        .then((response) => {
          vm.chargersList = response.data;
          vm.chargersListWithStatusOperational = vm.chargersList.filter(
            function (charger) {
              return (
                charger.status === "OPERATIONAL" && charger.type !== "VIRTUAL"
              );
            }
          );
          vm.chargersListWithStatusOnboarding = vm.chargersList.filter(
            function (charger) {
              return (
                charger.status === "ONBOARDING" && charger.type !== "VIRTUAL"
              );
            }
          );

          vm.chargersListWithStatusOnboarding.forEach(function (charger) {
            vm.onboardingChargers.push(charger);
          });

          vm.chargersListWithStatusOperational.forEach(function (charger) {
            vm.operationalChargers.push(charger);
          });
        })
        .catch((error) => {
          console.log("Error occured while getting chargers", error);
        });
    },
  },
  beforeMount() {
    this.getAllChargers();
    this.$store.dispatch("initEssentialDatasets");
  },
};
</script>
<style scoped src="@/assets/css/energia.min.css"></style>
